import React from "react";
export const About = () => <>
                        <h1>ABOUT</h1>
<p>
Dear Progress is a think tank agency that provides business-oriented approach to cross borders industry challenges including nurturing new names, developing sales strategies, establishing relationships with worldwide retailers, and providing expertise for big fashion players. 
Dear Progress was founded in January 2018 by Denis Erkhov and Sasha Krymova. Since then they developed a unique team that brings creative vision and exceptional operations through consultancy, research, and execution.
Based in Russia and Europe they both represent professional knowledge that influences industry internationally.
</p>
</>;
export default About;
